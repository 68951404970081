import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { PdfField, FieldType } from '../document/document.types';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class DataSharingService {
  private _fieldsSubject = new BehaviorSubject<PdfField[]>([]);
  public fields$ = this._fieldsSubject.asObservable();

  setFieldArray(fields: PdfField[]): void {
    this._fieldsSubject.next(fields);
  }

  removeFieldFromPanel(fieldId: string): void {
    this._fieldsSubject.next(this._fieldsSubject.value.filter(f => f.id !== fieldId));
  }


  addFieldToPanel(field: any): void {
    this.panelSource.next(field);
  }

  addWorkflowToPanel(workflow: any): void {
    this.workflowSource.next(workflow);
  }

  setSelectedFieldArray(fields: PdfField[]): void {
    this.selectedFieldArraySource.next(fields);
  }
  public batchCountSource = new BehaviorSubject<number>(1);
  batchCount = this.batchCountSource.asObservable();

  public imagesSource = new BehaviorSubject<any[]>([]);
  currentImages = this.imagesSource.asObservable();

  private totalBatchSource = new BehaviorSubject<number>(0);
  totalBatch = this.totalBatchSource.asObservable();

  private documentSource = new BehaviorSubject<any>(null);
  documentId = this.documentSource.asObservable();

  private totalImagesSource = new BehaviorSubject<number>(0);
  totalImages = this.totalImagesSource.asObservable();

  public documentNameSource = new BehaviorSubject<string>('');
  documentName = this.documentNameSource.asObservable();

  private showAnotationSource = new BehaviorSubject<boolean>(true);
  showAnotation = this.showAnotationSource.asObservable();

  private selectedLanguageSource = new BehaviorSubject<string>('');
  selectedLanguage = this.selectedLanguageSource.asObservable();

  private panelSource = new BehaviorSubject<any>(null);
  fieldPanel = this.panelSource.asObservable();

  private workflowSource = new BehaviorSubject<any>(null);
  workflow = this.workflowSource.asObservable();

  constructor(private _httpClient: HttpClient) { }

  private saveFieldSubject = new Subject<{ field: PdfField, pageNumber: number }>();
  saveField$ = this.saveFieldSubject.asObservable();

  triggerSaveField(field: PdfField, pageNumber: number): void {
    this.saveFieldSubject.next({ field, pageNumber });
  }

  updateBatchCount(count: number): void {
    this.batchCountSource.next(count);
  }

  updateImages(images: any[]): void {
    this.imagesSource.next(images);
  }

  setBatch(batch: number): void {
    this.totalBatchSource.next(batch);
  }

  setDocumentId(id: any): void {
    this.documentSource.next(id);
  }


  public selectedFieldArraySource = new BehaviorSubject<PdfField[] | null>(null);
  selectedFieldArray$ = this.selectedFieldArraySource.asObservable();

  setTotalImages(total: number): void {
    this.totalImagesSource.next(total);
  }

  setDocumentName(name: string): void {
    this.documentNameSource.next(name);
  }

  setShowAnotation(show: boolean) {
    this.showAnotationSource.next(show);
  }

  setSelectedLanguage(lang: string): void {
    this.selectedLanguageSource.next(lang);
  }
}
